input[type="search"] {
  height: 19px;

}
textarea#txtEmail {
 min-height: 158px;
}
div#panelActions {
   margin-top: 5px;
   left: 0.1%;
   width: 99.9%;
}  
div#panelEmail {
 margin-top: 5px;
 left: 0.1%;
 width: 99.9%;;
}  
div#panelQuest {
 margin-top: 5px;
 left: 0.1%;
 width: 99.9%;
}  
.card.direct-chat.direct-chat-primary.cardAddBgg {
 position: relative;
 left: 10%;
 top: 10px; 
 width : 80%;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle {
 background: none;
 color: #0012ff;
 font-weight: 700;
 font-size: 18px;
}
.tabulator .tabulator-header .tabulator-col {
 display: inline-block;
 position: relative;
 box-sizing: border-box;
 border-right: 1px solid #aaa;
 background: #343b4100;
 text-align: left;
 vertical-align: bottom;
 overflow: hidden;
}
.tabulator-headers {
 max-height: 60px;
}
.tabulator .tabulator-header .tabulator-col.tabulator-sortable .tabulator-col-title {
 padding-right: 15px;
}
.container-login100-form-btn {
 padding-top: 25px;
}
.example-table-theme{
 background-color:#ccc;
 border: 1px solid #333;
 border-radius: 10px;
}

.example-table-theme.tabulator{
 background-color:#ccc;
 border: 1px solid #333;
 border-radius: 10px;
}

/*Theme the header*/
#example-table-theme .tabulator-header {
 background-color:#333;
 color:#fff;
}

p#labelboleto {
 line-height: 0px;
}

/*Allow column header names to wrap lines*/
#example-table-theme .tabulator-header .tabulator-col,
#example-table-theme .tabulator-header .tabulator-col-row-handle {
 white-space: normal;
}

/*Color the table rows*/
#example-table-theme .tabulator-tableHolder .tabulator-table .tabulator-row{
 color:#fff;
 background-color: #666;
}

/*Color even rows*/
 #example-table-theme .tabulator-tableHolder .tabulator-table .tabulator-row:nth-child(even) {
 background-color: #444;
}
.react-grid-HeaderCell {
 padding: 4px;
}
div#divcalcular {
 border: ridge;
 padding-left: inherit;
 margin-left: auto;
 display: block;
}
button#btnCloseQuest {
 margin-top: 10px;
}
label#labelTCalc {
 background: aliceblue;
 width: -webkit-fill-available;
 text-align: center;
}
form#formRespQuestion {
 font-size: small;
}
input#inputLucro {
 background: darkgreen;
 color: white;
 font-style: italic;
 font-family: cursive;
 text-align: center;
}
button#btnGerarProp {
 margin-top: 10px;
}
.load {
 width: 50px;
 height: 50px;
 position: absolute;
 top: 250px;
 left: 40%;
 color: #2daabf;
 z-index: 100;
}
.card-body.locCont {
 /* flex: unset; */
 padding: 0;
}
/* .card-header.ui-sortable-handle.gertitle.locCont {
 background: #353b42;
} */
.react-confirm-alert {
 max-width: 80%;
}
p#pmodal {
 text-align: center;
}
a.abgg:hover {
 color: #ff0000;
}
a.abgg {
 font-weight: bold;
 font-family: sans-serif;
 font-style: oblique;
}
button#btnListarProp {
 margin-top: 10px;
}
label#labelTipoG {
 color: black;
 display: contents;
}
label#labelTipoP {
 color: black;
 display: contents;
}
label#labelTipoI {
 color: black;
 display: contents;
}
input#inputVTotal {
 background: #0c69ab;
 color: white;
 font-style: italic;
 font-family: cursive;
 text-align: center;
}
input#txtRetira {
 float: left;
}
.info-box-content-bgg {
 padding: 20px 10px;
 flex: 1;
}
h1.box-title-bgg {
 display: flex;
 padding: 10px 10px 10px 18px;
 font-size: inherit;
}
.btn.btn-info.btn-block.btnbggp {
 margin-bottom: 25px;
 margin-top: -10px;
}
p.bgg_p_lav {
 margin: 0px 0px 0px 0px;
 font-size: 15px;
 text-align: center;
}
p.bgg_p_lav_dt {
 margin: 0px 0px 0px 0px;
 font-size: 16px;
 color: red;
 text-align: center;
}
p.bgg_p_lav_t {
 margin: 0px 0px 4px 0px;
 font-size: 13px;
 font-weight: bold;
 text-align: center;
}
p#txtForaPrazo {
  font-size: 0.6em;
  padding-top: 10px;
  font-family: 'Poppins-Regular';
}
.card.card-info.cardAddBgg {
  overflow: scroll!important;
}
a#aZap {
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
div#dbtn\ {
  padding-left: 12px!important;
  padding-right: 12px!important;
  min-height: 3em;
}

select#selectConddiv {
  white-space: nowrap !important;
  margin-left: 18px!important;
  position: relative!important;
  top: -2px!important;
  width: 18em!important;  
}

div#cardtable\ {
  overflow: scroll;
}

