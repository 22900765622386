select#selectConddiv {
  white-space: nowrap !important;
  margin-left: 18px!important;
  position: relative!important;
  top: -2px!important;
  width: 18em!important;  
}
select#selectConddivPD {
  white-space: nowrap !important;
  margin-left: 18px!important;
  position: relative!important;
  top: -2px!important;
  width: 18em!important;  
}
.ditem {
  background: #efeded;
  border-radius: 10px;
  margin-bottom: 3px;
  max-width: 30em;
}
p.btn.btn-success.btn-block.btnbggp {
  margin-top: -10px;
}
div#divpIdaprov {
  padding-bottom: 1px;
}
div#divbtn_ressalva {
  min-height: 3em;
  margin-top: -17px;
}
textarea#ta1 {
  width: 320px;
  background-color: ivory;
}
textarea#ta1dis {
  width: 320px;
  background-color: papayawhip;
}
p#p_ressalva {
  text-align: center;
  font-size: small;
  color: blue;
}