thead {
  background-color: #b0c4de!important;
}
thead.thbgg1 {
  background-color: #b0c4de!important;
}
tr.trbgg1 {
  background-color: #b0c4de!important;
}
tr.ctrbggFuturo {
  background-color: #b0c4de;
}