.divMenuBgg {
  background-image: url(http://localhost:3000/images/bg-1.jpg);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.05;
  background-size: cover;
}

ul#iconmenubg {
  margin-left: -15px;
}

@media (max-width: 600px) {
  
/* menulateral1 */
  .sidebar-mini .nav-sidebar,
  .sidebar-mini .nav-sidebar>.nav-header,
  .sidebar-mini .nav-sidebar .nav-link {
    white-space: nowrap;
    overflow: hidden;
  }

  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .main-footer,
  .sidebar-mini.sidebar-collapse .main-header {
    margin-left: 3.1rem !important;
  }

  .sidebar-mini.sidebar-collapse .main-sidebar,
  .sidebar-mini.sidebar-collapse .main-sidebar:before {
    margin-left: 0;
    width: 3.1rem;
  }

  .sidebar-mini.sidebar-collapse .sidebar .user-panel>.info,
  .sidebar-mini.sidebar-collapse .nav-sidebar .nav-link p,
  .sidebar-mini.sidebar-collapse .brand-text {
    visibility: hidden;
    opacity: 0;
    margin-left: -25px;
  }

  .sidebar-mini.sidebar-collapse .nav-sidebar .nav-link p {
    width: 0;
  }

  .nav-sidebar>.nav-item {
    margin-left: -18px !important;
  }

  .layout-navbar-fixed.sidebar-collapse .main-sidebar:hover .brand-link {
    width: 3.1em !important;
    height: 1.6em;
  }

  img.brand-image.img-circle.elevation-3 {
    max-height: 1em;
    position: fixed;
    top: 10px;
    left: 0;
  }

  .layout-navbar-fixed.sidebar-collapse .main-sidebar:hover .brand-link {
    width: 2.5em !important;
    height: 1.6em;
  }

  .layout-navbar-fixed.sidebar-collapse .brand-link {
    width: 3.1rem !important;
    height: 1.6em;

  }

  span.brand-text.font-weight-light {
    position: fixed;
    top: 3px;
    left: 50px;
  }

  .main-header .navbar-nav .nav-item {
    white-space: nowrap !important;
  }

  a.brand-link {
    min-height: 1.6em !important;
  }

  footer.main-footer {
    position: fixed;
    top: 95%;
    font-size: x-small;
    min-width: 88%;
    padding-top: 8px;
  }

  a#a_you {
    font-size: 10px;

  }

  nav.mt-2 {
    margin-top: 0 !important;
    padding-top: 0 !important;
    position: fixed;
    top: 2em;

  }

  div#divForaPrazo {
    margin-top: 5em;
  }

  div#dbtn\  {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
}
select#selectConddiv {
  white-space: nowrap !important;
  margin-left: 18px!important;
  position: relative!important;
  top: -2px!important;
  width: 18em!important;  
}
nav.z-index-max.position-fixed.d-flex.d-md-none.flex-row.justify-content-between.full-width.py-3.px-2.menu-mobile {
  /* top: auto; */
  /* display: flex!important; */
  z-index: 1050;
  width: 100%;
  bottom: 0px;
  background: white;
  box-shadow: 0 -112px 136px #0000000b, 0 -46.7909px 56.8175px #00000010, 0 -25.0167px 30.3774px #00000014, 0 -14.0241px 17.0293px #00000018, 0 -7.44811px 9.04414px #0000001d, 0 -3.09933px 3.76347px #00000029;
}
i-feather {
  fill: blue;
  height: 26px;
  width: 22px;
  color: blue;
  box-sizing: border-box;
  /* vertical-align: middle; */
  display: inline-block;
  fill: none;
  /* height: 24px; */
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
  /* width: 24px; */
}
.botao-home {
  background: white;
  border: 6px solid var(--branco);
  border-radius: 10000px;
  box-shadow: 0 -2px 4px #00000040;
  margin-top: -44px;
  padding: 10px;
}
a.d-flex.flex-column.align-items-center:hover {
  color: navy;
}
span.menubggmobilispan {
  font-size: x-small;
}